import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

const PetSolution = props => {
  const data = useStaticQuery(graphql`
      query PetSolutionQuery {
        allPetSolutionJson {
          edges {
            node {
              icon
              name
              description
            }
          }
        }
      }
  `);
  return (
    <div className="pet-solution">
      <h2>宠物行业解决方案</h2>
      <div className="pet-solution_container">
        {data.allPetSolutionJson.edges.map(({ node }, idx) => (
          <div key={`${node.name + idx}`} className="pet-solution_card">
            <picture>
              <source
                srcSet={`/images/2x/solution/${node.icon}@2x.png`}
                media="(min-resolution: 2dppx)"
              />
              <source
                srcSet={`/images/3x/solution/${node.icon}@3x.png`}
                media="(min-resolution: 3dppx)"
              />
              <img
                className={`pet-solution_card_icon pet-solution_card_icon_${node.name}`}
                src={`/images/1x/solution/${node.icon}@1x.png`}
                title={node.name}
                alt={node.name}
              />
            </picture>
            <div className="pet-solution_card_description">
              {node.description.map((des, i) => (
                <span key={`${des + i}`}>
                  {des}
                  <br />
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PetSolution;
