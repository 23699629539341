import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Partner from '../components/Partner';
import PetSolution from '../components/PetSolution';
import FlexPicture from '../components/FlexPicture';
const Home = props => {
  const title = '魔鬼鱼官网';
  return (
    <Layout bodyClass="page-home">
      <SEO title={title} />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <div className="intro-wrapper">
        <div className="intro">
          <div className="index-container">
            <div className="index-header">
              <div className="banner banner-header">
                <FlexPicture
                  icon="intro/home_banner"
                  name="banner"
                  className="banner banner-img"
                />
                <div className="banner_description">
                  <span className="quote">“</span>
                  <span className="pet-one">做宠物</span>
                  <br />
                  <span className="mantas-two">就用魔鬼鱼</span>
                  <br />
                  <span className="profession-three">专业·好用·便捷</span>
                </div>
              </div>
            </div>
            <div className="solution">
              <PetSolution />
            </div>
            <div style={{ backgroundColor: '#5938E3' }}>
              <div className="b2b-pic">
                <FlexPicture
                  icon="intro/b2b_banner"
                  name="banner"
                  className="b2b-pic"
                />
                <div className="b2b-pic_description">
                  <span className="quote">“</span>
                  <span className="pet-one">宠物行业</span>
                  <div className="mantas-two">一站式订货平台</div>
                  <div className="profession-three">全国门店都在用</div>
                </div>
              </div>
            </div>
            <div className="solution">
              <Partner />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
