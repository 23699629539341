import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

const Partner = props => {
  const data = useStaticQuery(graphql`
      query PartnerQuery {
        partnerJson {
          first {
            name
            url
          }
          second {
            name
            url
          }
          third {
            name
            url
            id
          }
        }
      }
  `);
  return (
    <div className="partner">
      <h2>合作伙伴</h2>
      <div className="partner_wrapper">
        <div className="partner_container">
          <div>
            {data.partnerJson.first.map((node, idx) => (
              <picture
                key={`${node.name + idx}`}
              >
                <source
                  srcSet={`/images/2x/partner/${node.url}@2x.png`}
                  media="(min-resolution: 2dppx)"
                />
                <source
                  srcSet={`/images/3x/partner/${node.url}@3x.png`}
                  media="(min-resolution: 3dppx)"
                />
                <img
                  className="partner_container_item"
                  src={`/images/1x/partner/${node.url}@1x.png`}
                  title={node.name}
                  alt={node.name}
                />
              </picture>
            ))}
          </div>
          <div>
            {data.partnerJson.second.map((node, idx) => (
              <picture
                key={`${node.name + idx}`}
              >
                <source
                  srcSet={`/images/2x/partner/${node.url}@2x.png`}
                  media="(min-resolution: 2dppx)"
                />
                <source
                  srcSet={`/images/3x/partner/${node.url}@3x.png`}
                  media="(min-resolution: 3dppx)"
                />
                <img
                  className="partner_container_item"
                  src={`/images/1x/partner/${node.url}@1x.png`}
                  title={node.name}
                  alt={node.name}
                />
              </picture>
            ))}
          </div>
          <div>
            {data.partnerJson.third.map((node, idx) => (
              <picture
                key={`${node.name + idx}`}
              >
                <source
                  srcSet={`/images/2x/partner/${node.url}@2x.png`}
                  media="(min-resolution: 2dppx)"
                />
                <source
                  srcSet={`/images/3x/partner/${node.url}@3x.png`}
                  media="(min-resolution: 3dppx)"
                />
                <img
                  className={`partner_container_item partner_container_item-${node.id}`}
                  src={`/images/1x/partner/${node.url}@1x.png`}
                  title={node.name}
                  alt={node.name}
                />
              </picture>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
