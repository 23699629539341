import React from 'react';
import '../scss/style.scss';

const FlexPicture = props => (
  <>
    <picture>
      <source
        srcSet={`/images/2x/${props.icon}@2x.png`}
        media="(min-resolution: 2dppx)"
      />
      <source
        srcSet={`/images/3x/${props.icon}@3x.png`}
        media="(min-resolution: 3dppx)"
      />
      <img
        className={props.className}
        src={`/images/1x/${props.icon}@1x.png`}
        title={props.name}
        alt={props.name}
      />
    </picture>
  </>
);

export default FlexPicture;
